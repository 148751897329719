import React, { useState, useContext } from "react";
import {
  GetVendorInvoice,
  GetVendorPayments,
  GetVendorStatement,
} from "../../../api";
import { AppContext } from "../../../AppContext";
import InvoiceViewer from "./PendingInvoices";

const FinancialDashboard = () => {
  const { context } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("summary");
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mpesaRefNo, setMpesaRefNo] = useState("");

  const { invoice } = GetVendorInvoice();
  const { payments } = GetVendorPayments();
  const { statement } = GetVendorStatement({ DateRange: context?.DateRange });

  const styles = {
    container: {
      padding: "24px",
      maxWidth: "1800px",
      margin: "0 auto",
      backgroundColor: "#f8f9fa",
      minHeight: "100vh",
    },
    header: {
      marginBottom: "32px",
      borderBottom: "2px solid #e9ecef",
      paddingBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerTitle: {
      fontSize: "28px",
      fontWeight: "700",
      color: "#1a1a1a",
      margin: "0",
    },
    addPaymentButton: {
      padding: "12px 24px",
      backgroundColor: "#40c057",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "600",
      transition: "all 0.2s ease",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      boxShadow: "0 4px 12px rgba(64, 192, 87, 0.3)",
    },
    tabsContainer: {
      display: "flex",
      gap: "12px",
      marginBottom: "32px",
    },
    tab: {
      padding: "12px 24px",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "all 0.2s ease",
      fontSize: "15px",
      fontWeight: "500",
      backgroundColor: "#e9ecef",
      color: "#495057",
    },
    activeTab: {
      backgroundColor: "#228be6",
      color: "white",
      boxShadow: "0 2px 4px rgba(34, 139, 230, 0.2)",
    },
    summaryGrid: {
      display: "flex",
      gap: "20px",
      marginBottom: "40px",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    card: {
      flex: "1",
      minWidth: "200px",
      maxWidth: "340px",
      backgroundColor: "white",
      borderRadius: "12px",
      padding: "20px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.04)",
      transition: "all 0.3s ease",
      border: "1px solid #e9ecef",
    },
    cardHover: {
      transform: "translateY(-4px)",
      boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
    },
    cardTitle: {
      fontSize: "14px",
      color: "#868e96",
      margin: "0",
      fontWeight: "500",
    },
    cardValue: {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1a1a1a",
      margin: "0",
    },
    cardTrend: {
      fontSize: "13px",
      color: "#40c057",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      marginTop: "8px",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: "0",
    },
    th: {
      textAlign: "left",
      padding: "16px",
      fontSize: "14px",
      fontWeight: "600",
      color: "#495057",
      backgroundColor: "#f8f9fa",
      borderBottom: "2px solid #e9ecef",
    },
    td: {
      padding: "16px",
      fontSize: "14px",
      color: "#495057",
      borderBottom: "1px solid #e9ecef",
    },
    amount: {
      fontWeight: "600",
      color: "#1a1a1a",
    },
    status: {
      padding: "6px 12px",
      borderRadius: "15px",
      fontSize: "12px",
      fontWeight: "500",
    },
    paid: {
      backgroundColor: "#40c057",
      color: "white",
    },
    pending: {
      backgroundColor: "#ffd43b",
      color: "#1a1a1a",
    },
    overdue: {
      backgroundColor: "#fa5252",
      color: "white",
    },
    sectionTitle: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#1a1a1a",
      marginBottom: "20px",
    },
    paymentSection: {
      backgroundColor: "white",
      borderRadius: "12px",
      padding: "24px",
      marginTop: "32px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.04)",
      border: "1px solid #e9ecef",
    },
    paymentOptions: {
      display: "flex",
      gap: "16px",
      marginBottom: "24px",
    },
    paymentOption: {
      flex: 1,
      padding: "20px",
      borderRadius: "8px",
      border: "2px solid #e9ecef",
      cursor: "pointer",
      textAlign: "center",
      transition: "all 0.2s ease",
    },
    selectedOption: {
      borderColor: "#228be6",
      backgroundColor: "#e7f5ff",
    },
    inputGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#495057",
    },
    input: {
      width: "100%",
      padding: "12px",
      borderRadius: "6px",
      border: "1px solid #dee2e6",
      fontSize: "14px",
      marginBottom: "16px",
    },
    submitButton: {
      padding: "12px 24px",
      backgroundColor: "#40c057",
      color: "white",
      border: "none",
      borderRadius: "6px",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "500",
      transition: "all 0.2s ease",
    },
    paymentHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "24px",
    },
    cancelButton: {
      padding: "8px 16px",
      backgroundColor: "#e9ecef",
      color: "#495057",
      border: "none",
      borderRadius: "6px",
      cursor: "pointer",
      fontSize: "14px",
      marginRight: "12px",
    },
  };

  const totalInvoices = invoice.reduce(
    (sum, inv) => sum + inv.InvoiceAmount,
    0
  );
  const outstandingBalance = invoice.reduce(
    (sum, inv) =>
      inv.InvoiceStatus !== "Paid" ? sum + inv.InvoiceAmount : sum,
    0
  );
  const totalPayments = payments.reduce(
    (sum, payment) => sum + payment.TransAmount,
    0
  );
  const overdueInvoices = invoice.filter(
    (inv) =>
      inv.InvoiceStatus === "Overdue" && new Date(inv.DueDate) < new Date()
  ).length;
  const activeStations = statement.length;

  const SummaryCard = ({ title, value, trend, color }) => (
    <div
      style={styles.card}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-4px)";
        e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,0,0,0.08)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 2px 8px rgba(0,0,0,0.04)";
      }}
    >
      <div style={styles.cardHeader}>
        <h3 style={styles.cardTitle}>{title}</h3>
      </div>
      <p style={{ ...styles.cardValue, color: color }}>{value}</p>
      {trend && <div style={styles.cardTrend}>↑ {trend}</div>}
    </div>
  );

  const renderInvoiceStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <span style={{ ...styles.status, ...styles.pending }}>{status}</span>
        );
      case "Paid":
        return (
          <span style={{ ...styles.status, ...styles.paid }}>{status}</span>
        );
      case "Overdue":
        return (
          <span style={{ ...styles.status, ...styles.overdue }}>{status}</span>
        );
      default:
        return status;
    }
  };

  const PaymentForm = () => (
    <div style={styles.paymentSection}>
      <div style={styles.paymentHeader}>
        <h2 style={styles.sectionTitle}>Make Payment</h2>
        <button
          style={styles.cancelButton}
          onClick={() => setShowPaymentForm(false)}
        >
          Cancel
        </button>
      </div>

      {!paymentMethod ? (
        <div style={styles.paymentOptions}>
          <div
            style={{
              ...styles.paymentOption,
              ...(paymentMethod === "stkpush" ? styles.selectedOption : {}),
            }}
            onClick={() => setPaymentMethod("stkpush")}
          >
            <h3 style={styles.cardTitle}>STK Push</h3>
            <p>Receive M-Pesa prompt on your phone</p>
          </div>
          <div
            style={{
              ...styles.paymentOption,
              ...(paymentMethod === "refno" ? styles.selectedOption : {}),
            }}
            onClick={() => setPaymentMethod("refno")}
          >
            <h3 style={styles.cardTitle}>M-Pesa Reference</h3>
            <p>Enter your M-Pesa transaction reference number</p>
          </div>
        </div>
      ) : (
        <form>
          {paymentMethod === "stkpush" ? (
            <div style={styles.inputGroup}>
              <label style={styles.label}>Phone Number</label>
              <input
                type="text"
                style={styles.input}
                placeholder="Enter phone number (e.g., 254712345678)"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                pattern="^254\d{9}$" // Example: Validate Kenyan phone numbers (starts with 254)
                title="Phone number must be in the format 254712345678"
              />
            </div>
          ) : (
            <div style={styles.inputGroup}>
              <label style={styles.label}>M-Pesa Reference Number</label>
              <input
                type="text"
                style={styles.input}
                placeholder="Enter M-Pesa reference number"
                value={mpesaRefNo}
                onChange={(e) => setMpesaRefNo(e.target.value)}
                required
              />
            </div>
          )}
          <div>
            <button
              type="button"
              style={styles.cancelButton}
              onClick={() => setPaymentMethod(null)}
            >
              Back
            </button>
            <button type="submit" style={styles.submitButton}>
              Submit Payment
            </button>
          </div>
        </form>
      )}
    </div>
  );

  return (
    <div style={styles.container}>
      <div style={styles.tabsContainer}>
        {["summary", "invoices", "payments", "statements"].map((tab) => (
          <button
            key={tab}
            style={{
              ...styles.tab,
              ...(activeTab === tab ? styles.activeTab : {}),
            }}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {activeTab === "summary" && (
        <div>
          {!showPaymentForm ? (
            <InvoiceViewer
              invoices={invoice}
              setShowPaymentForm={setShowPaymentForm}
            />
          ) : (
            <PaymentForm />
          )}
        </div>
      )}

      {activeTab === "invoices" && (
        <div>
          <h3 style={styles.sectionTitle}>Invoices</h3>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Invoice No</th>
                <th style={styles.th}>Date Added</th>
                <th style={styles.th}>Due Date</th>
                <th style={styles.th}>Amount</th>
                <th style={styles.th}>Description</th>
                <th style={styles.th}>Status</th>
              </tr>
            </thead>
            <tbody>
              {invoice.map((inv) => (
                <tr key={inv.InvoiceID}>
                  <td style={styles.td}>{inv.InvoiceNo}</td>
                  <td style={styles.td}>
                    {new Date(inv.DateAdded).toLocaleDateString()}
                  </td>
                  <td style={styles.td}>
                    {new Date(inv.DueDate).toLocaleDateString()}
                  </td>
                  <td style={{ ...styles.td, ...styles.amount }}>
                    KES {inv.InvoiceAmount.toLocaleString()}
                  </td>
                  <td style={styles.td}>{inv.InvoiceDescription || "N/A"}</td>
                  <td style={styles.td}>
                    {renderInvoiceStatus(inv.InvoiceStatus)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "payments" && (
        <div>
          <h3 style={styles.sectionTitle}>Payments</h3>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Payment ID</th>
                <th style={styles.th}>Invoice NO</th>
                <th style={styles.th}>Transaction ID</th>
                <th style={styles.th}>Amount</th>
                <th style={styles.th}>Date Added</th>
                <th style={styles.th}>Transaction Date</th>
                <th style={styles.th}>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.PaymentID}>
                  <td style={styles.td}>{payment.PaymentID}</td>
                  <td style={styles.td}>{payment.InvoiceNO}</td>
                  <td style={styles.td}>{payment.TransActionID}</td>
                  <td style={{ ...styles.td, ...styles.amount }}>
                    KES {payment.TransAmount.toLocaleString()}
                  </td>
                  <td style={styles.td}>
                    {new Date(payment.DateAdded).toLocaleDateString()}
                  </td>
                  <td style={styles.td}>
                    {new Date(payment.TransactionDate).toLocaleDateString()}
                  </td>
                  <td style={styles.td}>{payment.PaymentStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "statements" && (
        <div>
          <h3 style={styles.sectionTitle}>Statements</h3>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Date</th>
                <th style={styles.th}>Description</th>
                <th style={styles.th}>Debit</th>
                <th style={styles.th}>Credit</th>
                <th style={styles.th}>Balance</th>
              </tr>
            </thead>
            <tbody>
              {statement.map((stmt, index) => (
                <tr key={index}>
                  <td style={styles.td}>
                    {new Date(stmt.Date).toLocaleDateString()}
                  </td>
                  <td style={styles.td}>{stmt.Description}</td>
                  <td style={{ ...styles.td, ...styles.amount }}>
                    KES {stmt.Debit.toLocaleString()}
                  </td>
                  <td style={{ ...styles.td, ...styles.amount }}>
                    KES {stmt.Credit.toLocaleString()}
                  </td>
                  <td style={{ ...styles.td, ...styles.amount }}>
                    KES {stmt.Balance.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FinancialDashboard;
