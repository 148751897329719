import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import {
  Menu,
  Print,
  PrintSharp,
  Email,
  WhatsApp,
  InsertDriveFile,
} from "@material-ui/icons";
import { Hidden, Tooltip } from "@mui/material";
import { AppContext } from "../../AppContext";
import ReactToPrint from "react-to-print";
import Tab from "../common/Tab/tab";
import { NavBar } from "../../api/data";
import { encryptSession } from "../../api";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";
import EmailSender from "./EmailSender";
import WhatsAppSender from "./WhatsAppSender";
import MutilpleStations from "../common/MutilpleStations";
import WarningButton from "./InvoiceButton";
import SearchableDropdown from "../common/MutilpleStations";

//const lightColor = "rgba(255, 255, 255, 0.7)";
const locations = encryptSession.getItem("loc") || [];
const initiallocd = encryptSession.getItem("locd") || {};

const styles = {
  border: "#44dc94",
  padding: "12px 30px",
  borderRadius: "50px",
  backgroundColor: "white",
  color: "#44dc94",
  cursor: "pointer",
  fontSize: "15px",
  outline: "none",
  marginRight: "20px",
};

const logo = {
  height: "70px",
  fontWeight: "bold",
  fontSize: "40px",
  color: "white",
  cursor: "pointer",
  marginTop: "10px",
};

function Header(props) {
  const history = useHistory();
  const [pageHeader, setPageHeader] = React.useState([]);
  const timeoutIdRef = React.useRef(null);

  const handleActivity = React.useCallback(() => {
    if (window.location.hostname !== "localhost") {
      clearTimeout(timeoutIdRef.current);
      const newTimeoutId = setTimeout(() => {
        logout();
      }, 900000);
      timeoutIdRef.current = newTimeoutId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handleActivity();
    const mouseMoveHandler = () => handleActivity();
    const keyDownHandler = () => handleActivity();

    window.addEventListener("mousemove", mouseMoveHandler);
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      clearTimeout(timeoutIdRef.current);
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var domain1 = window.location.href.split("/");
  React.useEffect(() => {
    const newPageHeader = NavBar.filter(
      (el) =>
        el.id.toLocaleLowerCase() ===
        domain1[domain1.length - 2].toLocaleLowerCase()
    );
    setPageHeader(newPageHeader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  function handleTabs(tab) {
    history.push(`/${tab.url}`);
  }

  const logout = async () => {
    const cacheKeys = await caches.keys();
    cacheKeys.forEach(async (key) => {
      await caches.delete(key);
    });
    sessionStorage.clear();
    history.push("/");
    window.location.reload();
  };

  const user = encryptSession.getItem("usn");
  const customer = encryptSession.getItem("cn");

  const { context, setContext, VendorImage, locd, setLocd } =
    React.useContext(AppContext);

  const [email, setEmail] = React.useState(false);
  const [phone, setPhone] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const componentRef = React.useRef();

  const storedValues = JSON.parse(localStorage.getItem("values"));

  React.useEffect(() => {
    const handleReload = () => {
      console.log("Reloading window...");
      window.location.reload();
    };

    if (context?.VendorID) {
      console.log("Adding event listener...");
      window.addEventListener("reload", handleReload);
    }

    return () => {
      console.log("Removing event listener...");
      window.removeEventListener("reload", handleReload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!context.VendorID) {
      const firstObject = locations[0];

      setContext((prevContext) => ({
        ...prevContext,
        selectedYear: new Date().getFullYear(),
        selectedMonth: new Date().getMonth() + 1,
        TradingName: encryptSession.getItem("cn"),
        VendorID: encryptSession.getItem("ci"),
        ImageID: VendorImage,
        ref: componentRef,
        locd: locd,
        VendorLocationID:
          storedValues?.VendorLocationID || firstObject?.VendorLocationID,
        VendorLocationName:
          storedValues?.VendorLocationName || firstObject?.VendorLocationName,
      }));
      setLocd(initiallocd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.VendorLocationName]);

  if (!storedValues && Array.isArray(locations) && locations.length > 0) {
    setContext({
      ...context,
      VendorLocationID: locations[0].VendorLocationID,
      VendorID: locations[0].VendorID,
      VendorLocationName: locations[0].VendorLocationName,
      ImageID: locations[0].ImageID,
    });
  }

  const exportToExcel = () => {
    const table = componentRef?.current?.querySelector("table");

    if (table) {
      const worksheet = XLSX.utils.table_to_sheet(table);
      const workbook = XLSX.utils.book_new();
      const truncatedSheetName = context.reporttitle
        ? context.reporttitle?.substring(0, 31)
        : "sheet1";
      XLSX.utils.book_append_sheet(workbook, worksheet, truncatedSheetName);
      XLSX.writeFile(workbook, truncatedSheetName + ".xlsx");
    }
  };

  const convertpf = () => {
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = context?.reporttitle || "crato";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const extractBase64Part = (fullBase64String) => {
    const base64Content = fullBase64String.split(",")[1];
    return base64Content;
  };

  const exportToPdf = async () => {
    await generatePDF();
    await new Promise((resolve) => setTimeout(resolve, 7000));
    await convertpf();
  };

  const generatePDF = async () => {
    const element = componentRef.current;
    const margin = 10; // Define the margin

    const options = {
      filename: `${context.reporttitle}.pdf`,
      html2canvas: {
        scale: 2,
        scrollX: 0,
        scrollY: 0,
        width: element?.scrollWidth + margin * 2, // Add margins
        height: element?.scrollHeight + margin * 2, // Add margins
        windowWidth: element?.scrollWidth + margin * 2, // Add margins
        windowHeight: element?.scrollHeight + margin * 2, // Add margins
        useCORS: true,
      },
      jsPDF: {
        unit: "pt",
        format: "a4",
        orientation: "portrait",
      },
    };

    const clonedElement = element?.cloneNode(true);
    document.body.appendChild(clonedElement);

    // Adjust styles to fit all content within the PDF including margins
    clonedElement.style.width = `${element.scrollWidth + margin * 2}px`;
    clonedElement.style.height = `${element.scrollHeight + margin * 2}px`;
    clonedElement.style.padding = `${margin}px`;

    // Calculate total height of cloned element
    const clonedHeight = clonedElement.offsetHeight;
    let currentPosition = 0;
    let pageHeight = 0;

    // Function to check if an element fits in the current page
    const fitsInCurrentPage = (elemHeight) => {
      return currentPosition + elemHeight <= pageHeight;
    };

    // Iterate through child elements and handle page breaks
    Array.from(clonedElement.children).forEach((child) => {
      const elemHeight = child.offsetHeight;

      if (!fitsInCurrentPage(elemHeight)) {
        currentPosition = 0;
        pageHeight = 0;
        child.classList.add("pagebreak");
      }

      currentPosition += elemHeight;
      pageHeight = Math.max(pageHeight, clonedHeight);
    });

    // Add CSS for page breaks
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        .pagebreak {
          page-break-before: always;
        }
      }
    `;
    document.head.appendChild(style);

    html2pdf()
      .from(clonedElement)
      .set(options)
      .outputPdf("dataurlstring")
      .then((pdfDataUrl) => {
        const base64data = extractBase64Part(pdfDataUrl);
        setFile(base64data);
        document.body.removeChild(clonedElement);
        document.head.removeChild(style); // Remove the style element
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        document.body.removeChild(clonedElement);
        document.head.removeChild(style); // Remove the style element
      });
  };

  return (
    <React.Fragment>
      {user && (
        <AppBar
          style={{ backgroundColor: "#2c3d4e", marginTop: "0px" }}
          position="sticky"
          elevation={0}
        >
          <Toolbar>
            {!props.isSidebarOpen ? (
              <Hidden mdDown>
                <IconButton
                  onClick={props.toggleSidebar}
                  edge="start"
                  sx={{
                    color: "white",
                    marginRight: "0px",
                  }}
                  aria-label="menu"
                >
                  <Menu />
                </IconButton>
              </Hidden>
            ) : null}
            <Hidden lgUp>
              <IconButton aria-label="menu" onClick={props.toggleMobileSidebar}>
                <Menu />
              </IconButton>
            </Hidden>
            <Grid container spacing={1} alignItems="center">
              <Grid xs item>
                {!user ? (
                  <div style={logo}>Esque Kenya</div>
                ) : (
                  <Typography color="inherit" variant="h5" component="h1">
                    {domain1[domain1.length - 2] === "customer"
                      ? customer?.toLocaleUpperCase()
                      : domain1[domain1.length - 2].toLocaleUpperCase()}
                  </Typography>
                )}
              </Grid>
              {/* {isDueDateLessThan10Days() && ( */}
              {/* <Grid item>
                <WarningButton />
              </Grid> */}
              {/* )} */}

              {user && (
                <>
                  <Grid item textAlign="center">
                    {Location && (
                      <div style={{ float: "right", marginLeft: "5px" }}>
                        <SearchableDropdown
                          locations={locations}
                          storedValues={storedValues}
                        />
                      </div>
                      // <div style={{ float: "right", marginLeft: "5px" }}>
                      //   <MutilpleStations
                      //     locations={locations}
                      //     storedValues={storedValues}
                      //   />
                      // </div>
                    )}
                  </Grid>

                  <Grid item>
                    <Tooltip title="log out">
                      <IconButton
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={() => logout()}
                      >
                        <Avatar
                          style={{
                            backgroundColor: props.isOnline ? "green" : "red",
                          }}
                          src="/static/images/avatar/1.jpg"
                          alt={user}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {!user && (
                <Grid item>
                  <button onClick={() => history.push("/login")} style={styles}>
                    Get started
                  </button>
                </Grid>
              )}
            </Grid>
          </Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Tabs textColor="inherit">
                <Tab Headers={pageHeader || []} handleTabs={handleTabs} />
              </Tabs>
            </Grid>
            <Grid item>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      backgroundColor: "#2c3d4e",
                      border: "1px solid white",
                      color: "white",
                    }}
                  >
                    <Print /> Print
                  </Button>
                )}
                content={() => componentRef.current}
                pageStyle="@page { size: A4; margin: 0; } @media print { body { margin: 1cm; } }"
              />
              <Button
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: "#2c3d4e",
                  border: "1px solid white",
                  color: "white",
                  marginLeft: "5px",
                }}
                onClick={() => exportToPdf()}
              >
                <PrintSharp />
                pdf
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{
                  marginLeft: "5px",
                  backgroundColor: "#2c3d4e",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={() => exportToExcel()}
              >
                <InsertDriveFile />
                Excel
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{
                  marginLeft: "5px",
                  backgroundColor: "#2c3d4e",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={() => {
                  generatePDF();
                  setEmail(!email);
                }}
              >
                <Email />
                email
              </Button>
              <Button
                size="small"
                variant="outlined"
                style={{
                  marginLeft: "5px",
                  backgroundColor: "#2c3d4e",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={() => {
                  generatePDF();
                  setPhone(!phone);
                }}
              >
                <WhatsApp />
                phone
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      )}
      <EmailSender pdfBlob={file} open={email} setOpen={setEmail} />
      <WhatsAppSender pdfBlob={file} open={phone} setOpen={setPhone} />
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
