import {
  MonetizationOn,
  HomeTwoTone,
  ProductionQuantityLimits,
  ShoppingBag,
  Summarize,
  Business,
  AutoModeOutlined,
} from "@mui/icons-material";

const Menuitems = [
  {
    href: "/dashboard/dashboard",
    icon: HomeTwoTone,
    title: "DASHBOARD",
    type: [1],
  },
  {
    href: "/sales/customer",
    icon: ShoppingBag,
    title: "SALES",
    type: [1],
  },
  {
    href: "/inventory/report",
    icon: ProductionQuantityLimits,
    title: "INVENTORY",
    type: [1],
  },
  {
    href: "/finance/statement",
    icon: MonetizationOn,
    title: "FINANCE",
    type: [1],
  },
  {
    href: "/shift/clock",
    icon: Summarize,
    title: "SHIFT ADMIN",
    type: [1],
  },
  {
    href: "/consolidated/dashboard",
    icon: Business,
    title: "SUMMARY",
    type: [1],
  },
  {
    href: "/automation/automation",
    icon: AutoModeOutlined,
    title: "AUTOMATION",
    type: [4, 5, 7, 8],
  },
];

export default Menuitems;
