import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
  } from "react";
  import { AppContext } from "../../AppContext";
  import { encryptSession } from "../../api";
  import moment from "moment";
  import { DateTimeFormat, DefaultContextDateRange, getNairobiTimeFromLocal } from "./Functions";
  
  const SearchableDropdown = ({ locations, storedValues }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(
      storedValues ? [storedValues] : [locations[0]]
    );
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const { context, setContext, setVendorImage, setLocd } =
      useContext(AppContext);
    const [startTime, endTime] = DefaultContextDateRange("day");
    const dropdownRef = useRef(null);
    const [dropdownHeight, setDropdownHeight] = useState(300);
  
    const filteredOptions = locations?.filter((option) =>
      option.VendorLocationName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const handleOnChange = useCallback(
      (newValues) => {
        let updateValue;
  
        if (newValues.length === 0) {
          // If no options are selected, use a default value
          updateValue = {
            VendorLocationID: 0,
            VendorLocationIDs: "",
            VendorLocationName: "",
            VendorID: encryptSession.getItem("ci"),
            locd: encryptSession.getItem("locd"),
          };
        } else if (newValues.length === 1) {
          // If only one option is selected, use its values
          const selectedOption = newValues[0];
          updateValue = {
            ...selectedOption,
          };
        } else {
          // If multiple options are selected
          const vendorLocationNames = newValues
            .map((v) => v.VendorLocationName)
            .join(", ");
          const vendorLocationIDs = newValues
            .map((v) => v.VendorLocationID)
            .join(",");
          updateValue = {
            VendorLocationID: 0,
            VendorLocationIDs: vendorLocationIDs,
            VendorLocationName: `${newValues.length} Stations Selected`,
            VendorID: encryptSession.getItem("ci"),
            locd: newValues?.locd,
            ImageID: newValues[0].ImageID,
            TradingName: newValues[0].TradingName,
            ...newValues[0],
          };
        }
  
        setContext((prevContext) => ({
          ...prevContext,
          ...updateValue,
        }));
        setVendorImage(updateValue.ImageID);
        localStorage.setItem("values", JSON.stringify(updateValue));
        setLocd(updateValue);
        encryptSession.setItem("cn", updateValue.TradingName);
        encryptSession.setItem("cim", updateValue.ImageID);
        encryptSession.setItem("locd", updateValue);
        encryptSession.setItem("ci", updateValue.VendorID);
      },
      [setContext, setVendorImage, setLocd]
    );
  
    const handleDateChange = useCallback(
      (start, end) => {
        // Use the current context dates as fallbacks instead of startTime/endTime
        const currentStartDate = context?.DateRange_?.[0] || startTime;
        const currentEndDate = context?.DateRange_?.[1] || endTime;
  
        // When changing start date, keep the existing end date
        // When changing end date, keep the existing start date
        const newStartDate = start || currentStartDate;
        const newEndDate = end || currentEndDate;
  
        setContext((prevContext) => ({
          ...prevContext,
          DateRange: [
            getNairobiTimeFromLocal(newStartDate),
            getNairobiTimeFromLocal(newEndDate),
          ],
          DateRange_: [newStartDate, newEndDate],
        }));
      },
      [context, setContext, startTime, endTime]
    );
  
    useEffect(() => {
      if (!context?.DateRange) {
        setContext((prevContext) => ({
          ...prevContext,
          DateRange: [
            getNairobiTimeFromLocal(startTime, "datetime"),
            getNairobiTimeFromLocal(endTime, "datetime"),
          ],
          DateRange_: [startTime, endTime],
        }));
      }
    }, [context, setContext, startTime, endTime]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);
  
    useEffect(() => {
      if (isVisible) {
        const calculateHeight = () => {
          const windowHeight = window.innerHeight;
          const dropdownTop =
            dropdownRef.current?.getBoundingClientRect().top || 0;
          const maxHeight = windowHeight - dropdownTop - 20;
          setDropdownHeight(Math.max(300, maxHeight));
        };
  
        calculateHeight();
        window.addEventListener("resize", calculateHeight);
  
        return () => window.removeEventListener("resize", calculateHeight);
      }
    }, [isVisible]);
  
    const renderDateInputs = () => {
      const startType = context?.timeRange === "time" ? "datetime-local" : "date";
      const endType = context?.timeRange === "time" ? "datetime-local" : "date";
      return (
        <>
          <input
            type={startType}
            value={moment(DateTimeFormat(context?.DateRange?.[0] || startTime)).format(
              startType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
            )}
            onChange={(e) => handleDateChange(e.target.value, null)}
            placeholder="Start Date"
            className="form-control"
            style={{
              width: "200px",
              borderRadius: "10px",
              padding: "8px",
              marginRight: "10px",
            }}
          />
          <input
            type={endType}
            value={moment(DateTimeFormat(context?.DateRange?.[1] || endTime)).format(
              endType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
            )}
            onChange={(e) => handleDateChange(null, e.target.value)}
            placeholder="End Date"
            className="form-control"
            style={{
              width: "200px",
              borderRadius: "10px",
              padding: "8px",
              marginRight: "10px",
            }}
          />
        </>
      );
    };
  
    const handleOptionChange = (option) => {
      let newSelectedOptions;
      if (isMultiSelect) {
        newSelectedOptions = selectedOptions.some(
          (item) => item.VendorLocationID === option.VendorLocationID
        )
          ? selectedOptions.filter(
              (item) => item.VendorLocationID !== option.VendorLocationID
            )
          : [...selectedOptions, option];
      } else {
        newSelectedOptions = [option];
        setIsVisible(false);
      }
      setSelectedOptions(newSelectedOptions);
      handleOnChange(newSelectedOptions);
      setSearchTerm("");
      encryptSession.setItem("cn", newSelectedOptions[0].TradingName);
      encryptSession.setItem("cim", newSelectedOptions[0].ImageID);
      encryptSession.setItem("locd", newSelectedOptions[0]);
      encryptSession.setItem("ci", newSelectedOptions[0].VendorID);
    };
  
    const toggleMultiSelect = () => {
      setIsMultiSelect(!isMultiSelect);
      if (!isMultiSelect) {
        setSelectedOptions([]);
        handleOnChange([]);
      } else {
        setSelectedOptions([selectedOptions[0] || locations[0]]);
        handleOnChange([selectedOptions[0] || locations[0]]);
      }
    };
  
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {context.timeRange === "nodate" ? null : renderDateInputs()}
        {context?.station !== "none" && (
          <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
            <input
              type="text"
              value={
                selectedOptions.length === 0
                  ? ""
                  : selectedOptions.length === locations.length
                  ? "All stations"
                  : selectedOptions
                      .map((option) => option.VendorLocationName)
                      .join(", ")
              }
              className="form-control fc-datepicker"
              onClick={() => setIsVisible(!isVisible)}
              placeholder="Select option(s)"
              style={{
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
                borderRadius: "10px",
              }}
              readOnly
            />
            {context?.fetching && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
            {isVisible && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: `${dropdownHeight}px`,
                  border: "1px solid #ccc",
                  zIndex: 1000,
                  backgroundColor: "#FFF",
                  boxSizing: "border-box",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#FFF",
                    zIndex: 1001,
                    padding: "8px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    {/* <input
                      type="checkbox"
                      id="multiSelect"
                      checked={isMultiSelect}
                      onChange={toggleMultiSelect}
                      style={{ marginRight: "8px" }}
                    />
                    <label
                      htmlFor="multiSelect"
                      style={{ color: "#333", userSelect: "none" }}
                    >
                      Select Multiple
                    </label> */}
                  </div>
                  <input
                    type="text"
                    className="form-control fc-datepicker"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      boxSizing: "border-box",
                    }}
                  />
                </div>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                    overflowY: "auto",
                    flexGrow: 1,
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <style>
                    {`
                      ul::-webkit-scrollbar {
                        display: none;
                      }
                    `}
                  </style>
                  {filteredOptions.map((option) => (
                    <li
                      key={option.VendorLocationID}
                      onClick={() => handleOptionChange(option)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        backgroundColor: "#FFF",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type={isMultiSelect ? "checkbox" : "radio"}
                        checked={selectedOptions.some(
                          (item) =>
                            item.VendorLocationID === option.VendorLocationID
                        )}
                        onChange={() => handleOptionChange(option)}
                        style={{ marginRight: "10px" }}
                      />
                      {option.VendorLocationName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default SearchableDropdown;
  