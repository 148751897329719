import React, { useState } from "react";
import { accountingFormat } from "../../common/Functions";

const InvoiceViewer = ({ invoices, onPaymentInitiated }) => {
  const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [mpesaReference, setMpesaReference] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const currentInvoice = invoices[currentInvoiceIndex];

  const handleManualPaymentSubmit = () => {
    if (mpesaReference.trim()) {
      onPaymentInitiated({
        type: "manual-mpesa",
        invoice: currentInvoice,
        reference: mpesaReference,
      });
      setPaymentMethod(null);
    }
  };

  const handleStkPushSubmit = () => {
    if (phoneNumber.trim()) {
      onPaymentInitiated({
        type: "stk-push",
        invoice: currentInvoice,
        phoneNumber: phoneNumber,
      });
      setPaymentMethod(null);
    }
  };

  const renderPaymentInstructions = () => {
    if (paymentMethod === "manual") {
      return (
        <div style={styles.paymentContainer}>
          <h3 style={styles.paymentHeader}>
            M-Pesa Manual Payment Instructions
          </h3>
          <ol style={styles.paymentList}>
            <li>Go to M-Pesa Menu on your phone</li>
            <li>Select "Lipa na M-Pesa"</li>
            <li>Choose "Pay Bill"</li>
            <li>
              Enter Business Number: <strong>123456</strong>
            </li>
            <li>
              Enter Account Number: <strong>{currentInvoice?.InvoiceNo}</strong>
            </li>
            <li>
              Enter Amount:{" "}
              <strong>
                {accountingFormat(currentInvoice?.PendingAmount || 0)}
              </strong>
            </li>
            <li>Enter your M-Pesa PIN</li>
          </ol>
          <div>
            <input
              type="text"
              value={mpesaReference}
              onChange={(e) => setMpesaReference(e.target.value)}
              placeholder="Enter M-Pesa Transaction Reference"
              style={styles.inputField}
            />
            <button
              onClick={handleManualPaymentSubmit}
              style={styles.confirmButton}
            >
              Confirm Payment
            </button>
          </div>
        </div>
      );
    }

    if (paymentMethod === "stk") {
      return (
        <div style={styles.paymentContainer}>
          <h3 style={styles.paymentHeader}>M-Pesa STK Push Payment</h3>
          <p style={styles.paymentDescription}>
            Enter your phone number to receive an M-Pesa payment prompt
          </p>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter Phone Number (e.g. 254712345678)"
            style={styles.inputField}
          />
          <button onClick={handleStkPushSubmit} style={styles.confirmButton}>
            Send STK Push
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={styles.container}>
      <div style={styles.invoiceContainer}>
        <div style={styles.header}>
          <h2 style={styles.companyName}>ESQUE LIMITED</h2>
          <p style={styles.companyInfo}>Nairobi, Kenya</p>
          <p style={styles.companyInfo}>info@esque.com</p>
        </div>

        <div style={styles.tableContainer}>
          <h3 style={styles.sectionHeader}>Pending Invoices</h3>
          <table style={styles.table}>
            <thead style={styles.tableHeader}>
              <tr>
                <th style={styles.tableHeaderCell}>Invoice No</th>
                <th style={styles.tableHeaderCell}>Due Date</th>
                <th style={styles.tableHeaderCell}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <tr 
                  key={index} 
                  style={styles.tableRow}
                  onClick={() => setCurrentInvoiceIndex(index)}
                >
                  <td style={styles.tableCell}>{invoice.InvoiceNo}</td>
                  <td style={styles.tableCellRight}>{invoice.DueDate}</td>
                  <td style={styles.tableCellRight}>
                    {accountingFormat(invoice.InvoiceAmount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {!paymentMethod && (
          <div style={styles.paymentOptions}>
            <button
              onClick={() => setPaymentMethod("stk")}
              style={styles.stkButton}
            >
              Pay via M-Pesa (STK Push)
            </button>
            <button
              onClick={() => setPaymentMethod("manual")}
              style={styles.manualButton}
            >
              Manual M-Pesa Payment
            </button>
          </div>
        )}

        {renderPaymentInstructions()}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
    padding: "20px",
    fontFamily: "'Inter', sans-serif",
  },
  invoiceContainer: {
    width: "100%",
    maxWidth: "700px",
    padding: "30px",
    backgroundColor: "white",
    borderRadius: "12px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "30px",
  },
  companyName: {
    fontSize: "24px",
    fontWeight: "700",
    color: "#2c3e50",
    marginBottom: "10px",
  },
  companyInfo: {
    color: "#7f8c8d",
    margin: "5px 0",
  },
  tableContainer: {
    marginBottom: "25px",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    overflow: "hidden",
  },
  tableHeader: {
    backgroundColor: "#f8f9fa",
    borderBottom: "1px solid #e0e0e0",
  },
  tableHeaderCell: {
    padding: "12px 15px",
    textAlign: "left",
    fontWeight: "600",
    color: "#2c3e50",
    textTransform: "uppercase",
    fontSize: "12px",
    letterSpacing: "0.5px",
  },
  tableRow: {
    transition: "all 0.3s ease",
    cursor: "pointer",
  },
  tableCell: {
    padding: "12px 15px",
    borderBottom: "1px solid #f0f0f0",
  },
  tableCellRight: {
    padding: "12px 15px",
    textAlign: "right",
    borderBottom: "1px solid #f0f0f0",
  },
  sectionHeader: {
    textAlign: "center",
    marginBottom: "15px",
    color: "#2c3e50",
    fontSize: "18px",
  },
  paymentOptions: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    marginTop: "20px",
  },
  stkButton: {
    flex: 1,
    backgroundColor: "#10b981",
    color: "white",
    padding: "12px 15px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    fontWeight: "600",
    transition: "background-color 0.3s ease",
  },
  manualButton: {
    flex: 1,
    backgroundColor: "#3b82f6",
    color: "white",
    padding: "12px 15px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    fontWeight: "600",
    transition: "background-color 0.3s ease",
  },
  paymentContainer: {
    backgroundColor: "#f8f9fa",
    padding: "25px",
    borderRadius: "8px",
    marginTop: "20px",
  },
  paymentHeader: {
    marginBottom: "15px",
    color: "#2c3e50",
    fontSize: "18px",
  },
  paymentList: {
    color: "#495057",
    paddingLeft: "20px",
    marginBottom: "15px",
  },
  paymentDescription: {
    marginBottom: "15px",
    color: "#6c757d",
  },
  inputField: {
    width: "100%",
    padding: "12px",
    marginBottom: "15px",
    borderRadius: "6px",
    border: "1px solid #ced4da",
    fontSize: "14px",
  },
  confirmButton: {
    backgroundColor: "#10b981",
    color: "white",
    border: "none",
    padding: "12px",
    borderRadius: "6px",
    cursor: "pointer",
    width: "100%",
    fontWeight: "600",
    transition: "background-color 0.3s ease",
  },
};

export default InvoiceViewer;